import React, {useEffect, useRef} from "react";
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import {Tile} from "ol/layer";
import * as control from "ol/control";
import BingMaps from "ol/source/BingMaps";

import "../../stylesheets/map.scss";

import {BING_API_KEY} from "../../utilities/resources";
import {getOuterHeights} from "../../utilities/utilities";


export const BingMap = (
	{
		activeImagery,
		id = "map",
		imagerySets = [
			{
				name: "RoadOnDemand",
				visible: false,
			},
			{
				name: "AerialWithLabelsOnDemand",
				visible: true,
			},
		],
		center,
		className = "map",
		layers,
		maxZoom = 23,
		zoom = 17,
		onMapClick,
		onMapMount,
	}
) => {
	let mapRef = useRef(null);
	let rastersRef = useRef([]);
	let view = new View({
		center: center,
		maxZoom: maxZoom,
		zoom: zoom,
	});

	imagerySets.forEach((set) => {
		rastersRef.current.push(
			new Tile({
				title: set.name,
				visible: set.visible,
				source: new BingMaps({
					key: BING_API_KEY,
					imagerySet: set.name,
				})
			})
		);
	});

	useEffect(() => {
			if (!mapRef.current) {
				mapRef.current = new Map({
					layers: [...rastersRef.current, ...layers],
					target: id,
					view: view,
					controls: control.defaults({
						attributionOptions: {
							collapsible: true,
						},
					}),
				});

				if (onMapMount) onMapMount(mapRef.current);

				mapRef.current.on("singleclick", (event) => {
					if (onMapClick) onMapClick(event);
				});

				let mapElement = document.getElementById(mapRef.current.getTarget());
				let mapRow = mapElement.closest(".row");
				let navbar = document.querySelector("nav");
				let aboveTheMap = mapRow.previousElementSibling;
				let belowTheMap = mapRow.nextElementSibling;

				let resizeObserver = new ResizeObserver((entries) => {
					let {width, height} = entries[0].contentRect;
					let combinedHeights = getOuterHeights([navbar, aboveTheMap, belowTheMap]);
					if (width <= 992) combinedHeights += getOuterHeights([belowTheMap.nextElementSibling]);
					let newHeight = height - combinedHeights - 50;
					mapElement.setAttribute("style", `height:${newHeight}px`);
				});
				resizeObserver.observe(document.body);
			}
		},
		[]
	);

	useEffect(() => {
			if (mapRef.current) {
				mapRef.current.getView().setCenter(center);

				rastersRef.current.forEach((raster, index) => {
					raster.setVisible(index === activeImagery);
				});
			}
		},
		[center, activeImagery]
	);

	return (
		<div className={"map-container"}>
			<div
				id={id}
				className={className}
			/>
		</div>
	)
}
