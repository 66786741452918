import React, {useState} from "react";

import {
	Row,
	Col,
	Button,
	Input,
	InputGroup,
} from "reactstrap";

import {FontAwesomeIcon as FAI} from "@fortawesome/react-fontawesome";

import "./social.scss";


export const SocialSharing = (
	{
		body,
		emailSubject,
		handleImageExport,
		url,
	}
) => {
	let [copied, setCopied] = useState(false);

	let copyURL = async () => {
		document.querySelector("input.email-input").select();
		let timeout = 1000;
		await navigator.clipboard.writeText(url);
		setCopied(true);
		setTimeout(() => {
			setCopied(false);
		}, timeout);
	};

	body = body + "%0A%0A" + encodeURIComponent(url);
	let copyButtonColor = copied ? "success" : "secondary"

	return (
		<section>
			<Row className={"mb-n1"}>
				<Col>
					<p className={"h4 text-center"}>
						Share
					</p>
				</Col>
			</Row>
			<Row className={"mb-2"}>
				<Col>
					<InputGroup>
						<Input
							className={"font-size-small email-input"}
							type={"text"}
							defaultValue={url}
						/>
						<Button
							outline
							title={"Copy to clipboard"}
							color={copyButtonColor}
							onClick={
								() => {
									copyURL()
								}
							}
						>
							<FAI icon={"copy"}/>
						</Button>
						<a
							title={"Send email"}
							className={"btn btn-outline-secondary border-start"}
							href={`mailto:?subject=${emailSubject}&body=${body}`}
						>
							<FAI className={"icon"} icon={"envelope"}/>
						</a>
					</InputGroup>
				</Col>
			</Row>
			<Row className={"mb-3"}>
				<Col>
					<div
						className={"d-flex flex-column align-items-center"}>
						<Button
							color={"secondary"}
							outline
							size={"sm"}
							onClick={
								() => handleImageExport()
							}
						>
							<FAI className={"icon"} icon={"save"}/> Save as
							image
						</Button>
					</div>
				</Col>
			</Row>
		</section>
	)
}
