import React, {Component} from 'react';

import {
	Input,
} from "reactstrap";

import * as countries from "../../../../utilities/countries-and-currencies.json";


export class CurrencySelector extends Component {

	render() {
		let defaultCountries = countries.default;

		return (
			<Input
				className={"ms-3 w-75"}
				type={"select"}
				name={"currency"}
				value={this.props.currencyLocale}
				onChange={
					(e) => this.props.updateCurrency(e.currentTarget.value)
				}
			>
				{Object.keys(defaultCountries).map((country, index) => {
					if (defaultCountries[country]['currency']['code']) {
						return (
							<optgroup
								data-code={defaultCountries[country]['currency']['code']}
								label={country}
								key={index}
							>
								{
									Object.keys(defaultCountries[country]['locales']).map((locale, indexx) => {
										return(
											<option
												value={defaultCountries[country]['locales'][locale]['locale']}
												key={`${index}${indexx}`}
											>
												{country} &ndash; {defaultCountries[country]['locales'][locale]['language']}
											</option>
										)
									})
								}
							</optgroup>
						)
					}
				})}
			</Input>
		)
	}
}

