import React, {useState} from 'react';
import {BingMap} from "../BingMap";

import "../../../stylesheets/ourtrees.scss";
import {fromLonLat} from "ol/proj";
import {getCenter} from "ol/extent";
import {getValueFromSearchParams} from "../../../utilities/utilities";


export const ClimateJusticeMap = (
	{
		children,
		variableMapParameters,
		originalFeatureLayer,
	}
) => {
	let [center, setCenter] = useState(
		fromLonLat([
			parseFloat(getValueFromSearchParams("longitude")),
			parseFloat(getValueFromSearchParams("latitude")),
		])
	);
	let onMapMount = (map) => {
		map.once("rendercomplete", () => {
			let extent = originalFeatureLayer.getSource().getExtent();
			setCenter(getCenter(extent))
			let options = {
				size: [290, 290],
				padding: [5, 5, 5, 5],
			};
			map.getView().fit(extent, options);
		});
	};
	let mapParameters = {
		imagerySets: [
			{
				name: "RoadOnDemand",
				visible: true
			},
		],
		activeImagery: 0,
		zoom: 4,
		maxZoom: 20,
		center: center,
		...variableMapParameters,
	};

	return (
		<React.Fragment>
			{children.textAboveTheMap}
			<BingMap
				{...mapParameters}
				onMapMount={onMapMount}
			/>
			{children.textBelowTheMap}
		</React.Fragment>
	);
};
